
export const tabVariantsAnimation = {
    initial: { opacity: 0, scale: 0.98 },
    animate: { opacity: 1, scale: 1 },
}

export const colors = {
    primary: '#1D24CA',
    "dark-blue": '#070F2B',
    gray: '#44474F'
};

const theme = {
    tabVariantsAnimation,
    colors: colors
}

export default theme